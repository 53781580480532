<template>
  <div>
    <b-card title="Vous pouvez rechercher les claims directement ici.">
      <b-row>
        <b-col
          cols="2"
        >
          <v-select
            v-model="descriptionType"
            placeholder="Claim"
            :options="descriptionOptions"
            transition=""
            @input="getClaims"
          />
        </b-col>
        <b-col
          cols="2"
        >
          <v-select
            v-model="subClaim"
            placeholder="Claim Option"
            :options="subClaimOptions"
            transition=""
            @input="getClaims"
          />
        </b-col>

        <b-col
          cols="2"
        >
          <v-select
            v-model="statusType"
            placeholder="Statut"
            :options="statusOptions"
            transition=""
            @input="getClaims"
          />
        </b-col>
        <b-col
          cols="2"
        >
          <v-select
            v-model="statusOpsType"
            placeholder="Statut OPS"
            :options="statusOptions"
            transition=""
            @input="getClaims"
          />
        </b-col>
        <b-col
          cols="3"
        >
          <v-select
            v-model="assignee"
            :options="assigneeOptions"
            :multiple="true"
            label="fullName"
            @input="getClaims"
          >
            <template #option="{ firstName, lastName }">
              <span class="ml-50 d-inline-block align-middle"> {{ firstName }} {{ lastName }}</span>
            </template>
            <template #selected-option="{ firstName, lastName }">
              <span class="ml-50 d-inline-block align-middle"> {{ firstName }}  {{ lastName }}</span>
            </template>
          </v-select>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="claims && claims.length"
          cols="2"
        >
          <download-csv
            :data="claims.map(c => ({ order: c._order.order.number, type: c.type,subClaim: c.subClaim,statusType: c.statusType,descriptionType: c.descriptionType,subDescriptionType: c.subDescriptionType,contactType: c.contactType,contactDescription: c.contactDescription,statusOpsType: c.statusOpsType,dueDate: c.dueDate,endDate: c.endDate,_createdAt: c._createdAt,_updatedAt: c._updatedAt}))"
            name="claim.csv"
          >
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>
      <!-- <chat-log :chat-data="chat"/> -->
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="claims"
          :fields="fields"
          :busy="loading"
        >
          <template #cell(_order)="data">
            <a :href="`/commande/${data.item._order.order.number}/claims?id=${data.item._id}`" target="_blank">{{ data.item._order.order.number }}</a>
          </template>
          <template #cell(client)="data">
            <span>{{ data.item._order.order.shipping.first_name }} {{ data.item._order.order.shipping.last_name }}</span>
          </template>
          <template #cell(dueDate)="data">
            <b-badge :variant="`light-${$moment(data.item.dueDate).diff($moment()) < 2 ? 'danger' : 'success'}`">
              {{ $moment(data.item.dueDate).format('DD/MM/YYYY') }}
            </b-badge>
          </template>
          <template #cell(_principalAssignee)="data">

            <b-badge :variant="`light-success`">
              {{ data.item._principalAssignee.lastName }}
            </b-badge>
            <b-badge v-for="(user, key) of data.item._otherAssignees" :key="key" :variant="`light-secondary`" class="ml-1">
              {{ user.lastName }}
            </b-badge>
          </template>
          <!-- <template #cell(status)="data">
            {{ data }}
          </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  BTable,
  VBPopover,
  BButton,
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    vSelect,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
    BButton,
    // flatPickr,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      dateFilter: null,
      required,
      baseClaims: [],
      claims: [],
      products: [],
      categories: [],
      assigneeOptions: [],
      assignee: [],
      claim: {},
      statusType: '',
      descriptionType: '',
      subClaim: '',
      descriptionOptions: ['IT', 'Tracking', 'Damaged', 'Delay', 'Additional Services', 'Wrong Product', 'Return', 'Bribes'],
      subClaimOptions: ['Agediss', 'BRT', 'Colisland', 'GLS', 'UPS Italy', 'UPS France', 'Geodiss'],
      statusOptions: ['First check', 'IT support', 'Waiting for forwarder feedback', 'OPS support', 'CS ongoing', 'Waiting for customer feedback', 'Check refund customer', 'Last check', 'Closed'],
      code: '',
      fields: [{ key: '_order', sortable: true }, { key: 'client', sortable: true }, { key: 'descriptionType', label: 'Claim', sortable: true }, { key: 'subDescriptionType', label: 'Provider', sortable: true }, { key: 'statusType', label: 'Status', sortable: true }, { key: 'statusOpsType', label: 'Status OPS', sortable: true }, { key: 'dueDate', label: 'Due Date', sortable: true }, { key: '_principalAssignee', label: 'Operator', sortable: true }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      const { users } = await this.$http.get('/user')
      await this.get()
      this.assignee = [this.me]
      this.assigneeOptions = users.map(u => ({ ...u, fullName: `${u.firstName} ${u.lastName}` }))
      await this.getClaims()
    } catch (err) {
      console.log(err)
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
  },
  methods: {
    ...mapActions('admin', ['get']),
    async getClaims() {
      try {
        this.loading = true
        console.log(this.assignee)
        const filter = { statusType: { $ne: 'Closed' } }
        if (this.statusType) {
          filter.statusType = this.statusType
        }
        if (this.statusOpsType) {
          filter.statusOpsType = this.statusOpsType
        }
        if (this.assignee?.length) {
          filter._otherAssignees = this.assignee?.map(i => i?._id)
        }
        if (this.subClaim) {
          filter.subClaim = this.subClaim
        }
        if (this.descriptionType) {
          filter.descriptionType = this.descriptionType
        }
        const {
          claims,
        } = await this.$http.get('/admin/claim', { params: { filter } })
        this.claims = claims
        this.baseClaims = claims
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.claims = this.baseClaims
      } else {
        const value = this.code
        console.log(value)
        this.claims = this.baseClaims.filter(claim => claim.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
